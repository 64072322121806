import React, {useMemo} from 'react';
import {useDropzone} from 'react-dropzone';
import axios from 'axios'

const baseStyle = {
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

export default function UploadBasic(props) {

  const uploadFiles = () => {
        let formData = new FormData();

        for (var i = 0; i < acceptedFiles.length; i++) {
            let file = acceptedFiles[i];
            formData.append('Files[]', file);
        }


        axios({
            method: 'post',
            url: 'http://normyapi.vxcontrol.pl/index.php/upload',
            data: formData,

            })


    }


  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject,
    acceptedFiles
  } = useDropzone({accept: {'*': []}});

  const style = useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  const files = acceptedFiles.map(file => (
    <li key={file.path}>
      {file.path} - {file.size} bytes
    </li>
  ));
  return (
    <div className="container">
      <div {...getRootProps({style})}>
        <input {...getInputProps()} />
        <p>Upuść pliki, lub kliknij i wybierz z listy</p>

      </div>
      <button onClick={uploadFiles}>Wyślij</button>
       <aside>
        <h4>Pliki</h4>
        <ul>{files}</ul>
      </aside>
    </div>
  );
}

